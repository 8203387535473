import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useXccCompareContext } from 'hooks/XccCompareContextProvider';
import { XccCompare, mapStringToDesignType } from 'hooks/xccCompareQuery';
import XccSimpleViewer from 'pages/XccViewer/XccSimpleViewer';
import { useUserDataContext } from 'providers/currentUser/UserDataProvider';
import { useGetProjectQuery } from 'services/queries/project.query';
import { Path } from 'types/paths.enum';

const XccCompareViewer = () => {
    const { collaborationSpaceId } = useUserDataContext();
    const [url, setUrl] = useState('');
    const [isOpenedFromUrl, setIsOpenedFromUrl] = useState(!collaborationSpaceId);
    const {
        projectId,
        firstRevisionId,
        secondRevisionId,
        designType = 'schematic',
    } = useParams() as {
        projectId: string;
        firstRevisionId: string;
        secondRevisionId: string;
        designType: string;
    };
    const navigate = useNavigate();

    const { data: currentProjectData } = useGetProjectQuery({
        collaborationSpaceId,
        projectId: projectId,
    });

    const {
        isInCache,
        getUrl,
        populateCache,
        addRevisions,
        isAnyLoading,
        openAction,
        onClose,
        isProcessing,
    } = useXccCompareContext();

    useEffect(() => {
        if (!(firstRevisionId && secondRevisionId)) {
            return;
        }
        const firstProject: XccCompare = {
            collaborationSpaceId,
            projectId,
            version: firstRevisionId,
            designType: mapStringToDesignType(designType),
        };
        const secondProject: XccCompare = {
            collaborationSpaceId,
            projectId,
            version: secondRevisionId,
            designType: mapStringToDesignType(designType),
        };
        const url = getUrl({
            projectId,
            firstRevisionId: firstProject.version,
            secondRevisionId: secondProject.version,
            designType: mapStringToDesignType(designType),
            collaborationSpaceId,
        });
        if (collaborationSpaceId) {
            if (isOpenedFromUrl) {
                if (
                    !(
                        (isInCache(firstProject) || isProcessing(firstProject)) &&
                        (isInCache(secondProject) || isProcessing(secondProject))
                    )
                ) {
                    addRevisions(firstProject, secondProject, { isFromUrl: isOpenedFromUrl });
                    populateCache(firstProject, secondProject);
                } else if (isInCache(firstProject) && isInCache(secondProject)) {
                    openAction();
                    url && setUrl(url);
                    setIsOpenedFromUrl(false);
                }
            } else {
                url && setUrl(url);
            }
        }
    }, [collaborationSpaceId, isAnyLoading]);

    const closeViewer = () => {
        onClose();
        navigate(`${Path.PROJECTS}/${projectId}/${firstRevisionId}`);
    };

    return (
        <XccSimpleViewer
            onClose={closeViewer}
            loading={isOpenedFromUrl}
            url={url}
            projectName={currentProjectData?.name}
        />
    );
};

export default XccCompareViewer;
